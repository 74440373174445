import { getHasClosingTimeForActivitySessionPassed } from "helpers/activity";
import { isAfterStartOfDay } from "helpers/date";
import type { Activity } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Client } from "types/model/client";

export function getIsActivitySessionAvailableToPurchase(
  activity: Activity<string>,
  activityGroup: ActivityGroup,
  client: Client
) {
  const isAvailable =
    activity.enabled &&
    isAfterStartOfDay(activity.date.start, client.timeZone) &&
    !getHasClosingTimeForActivitySessionPassed(activity, activityGroup);

  return isAvailable;
}
