export const DemoSiteBanner = () => {
  return (
    <div className="relative bg-indigo-600">
      <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="md:hidden">
              This is a Pembee Demo site. Like what you see?
            </span>
            <span className="hidden md:inline">
              This is a Pembee Demo site. Like what you see?
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <a
                href="https://www.pembee.app/sign-up"
                className="font-bold text-white underline"
              >
                {" "}
                Sign up for a free trial <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
